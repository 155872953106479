import { GET_NSO_DELIVERY_DATES_RESPONSE } from 'actions/sendABox';

const initialState = [];

const transformDeliveryDates = deliveryDates => {
  const groupedOptions = deliveryDates
    .map(menuWeek =>
      menuWeek.deliveryDates.map(deliveryDate => ({
        key: deliveryDate.date,
        value: deliveryDate.date,
        group: menuWeek.menuSlug,
      }))
    )
    .flat();

  return groupedOptions;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NSO_DELIVERY_DATES_RESPONSE:
      return transformDeliveryDates(action.response.deliveryDates);
    default:
      return state;
  }
};

export const selectDeliveryDates = state => state || {};
