import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import pickBy from 'lodash/pickBy';

import * as Routes from 'helpers/routes';

export const buildBasketEvent = basket => ({
  delivery_day: basket.deliveryDay,
  first_order: basket.firstOrder,
  meals: basket.meals && basket.meals.length ? basket.meals.map(meal => meal.title) : [],
  menu_id: basket.menu.slug,
  number_of_meals: basket.meals.length,
  servings: basket.servings,
  subtotal: basket.subtotalFormatted,
  mealPlan: basket.mealPlan,
});

export const buildViewEvent = action => {
  if (action.routeName === Routes.MEAL_DETAILS_PATH_NAME)
    return { meal_name: decodeURI(action.params.title) };
  if (action.routeName === Routes.MENU_PATH_NAME || action.routeName === Routes.SUMMARY_PATH_NAME)
    return { menu_id: action.params.menuSlug };
};

export const buildMealEvent = meal => ({
  meal_name: meal.title,
});

export const buildMealOptionEvent = (mealOptionGroup, mealOption) => ({
  selected_ingredient: mealOption.displayName,
  meal_option_group_title: mealOptionGroup.title,
});

const replaceFilterKeys = filters =>
  Object.keys(filters).reduce(
    (hash, filter) => ({
      ...hash,
      [filter.replace('filter', '')]: filters[filter],
    }),
    {}
  );

export const buildSearchEvent = action => {
  const search = action.params.search_term;
  const filters = pickBy(action.params, (_val, key) => key.startsWith('filter'));
  if (!search && isEmpty(filters)) return;
  return { search, filters: replaceFilterKeys(filters) };
};

export const buildReviewEvent = (rating, meal) => {
  return { rating, meal_title: meal.title };
};

export const buildFiltersObject = action => {
  const filters = isObject(action.value) ? Object.keys(action.value) : [action.value];
  return { filters };
};

export const buildReactivateCampaignEvent = campaign => ({
  campaign: campaign.name,
});
