import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

// checkout page
export const SET_NSO_BOX_RECIPIENT_INFO = 'SET_NSO_BOX_RECIPIENT_INFO';
export const SET_NSO_BOX_RECIPIENT_INFO_RESPONSE = 'SET_NSO_BOX_RECIPIENT_INFO_RESPONSE';

export const CREATE_NSO_BOX_RECIPIENT_INFO = 'CREATE_NSO_BOX_RECIPIENT_INFO';
export const CREATE_NSO_BOX_RECIPIENT_INFO_RESPONSE = 'CREATE_NSO_BOX_RECIPIENT_INFO_RESPONSE';

export const GET_NSO_BOX_RECIPIENT_INFO = 'GET_NSO_BOX_RECIPIENT_INFO';
export const GET_NSO_BOX_RECIPIENT_INFO_REQUEST = 'GET_NSO_BOX_RECIPIENT_INFO_REQUEST';
export const GET_NSO_BOX_RECIPIENT_INFO_RESPONSE = 'GET_NSO_BOX_RECIPIENT_INFO_RESPONSE';

// GET api/v2/boxes
export const GET_NSO_BOXES = 'GET_NSO_BOXES';
export const GET_NSO_BOXES_REQUEST = 'GET_NSO_BOXES_REQUEST';
export const GET_NSO_BOXES_RESPONSE = 'GET_NSO_BOXES_RESPONSE';
export const GET_NSO_BOXES_ERROR = 'GET_NSO_BOXES_ERROR';

// POST api/v2/boxes
export const CREATE_NSO_BOX = 'CREATE_NSO_BOX';
export const CREATE_NSO_BOX_RESPONSE = 'CREATE_NSO_BOX_RESPONSE';
export const UPDATE_BOX = 'UPDATE_BOX';
export const UPDATE_BOX_RESPONSE = 'UPDATE_BOX_RESPONSE';
export const GET_NSO_BOX = 'GET_NSO_BOX';
export const GET_NSO_BOX_RESPONSE = 'GET_NSO_BOX_RESPONSE';
export const SUBMIT_NSO_ORDER = 'SUBMIT_NSO_ORDER';
export const CONFIRM_NSO_ORDER = 'CONFIRM_NSO_ORDER';

// DELETE /api/v2/send_a_box/boxes/:id
export const DESTROY_NSO_BOX = 'DESTROY_NSO_BOX';
export const DESTROY_NSO_BOX_REQUEST = 'DESTROY_NSO_BOX_REQUEST';
export const DESTROY_NSO_BOX_RESPONSE = 'DESTROY_NSO_BOX_RESPONSE';
export const DESTROY_NSO_BOX_ERROR = 'DESTROY_NSO_BOX_ERROR';

// PUT/PATCH /api/v2/send_a_box/box_weekly_baskets/:id
export const UPDATE_BOX_WEEKLY_BASKET = 'UPDATE_BOX_WEEKLY_BASKET';
export const UPDATE_BOX_WEEKLY_BASKET_RESPONSE = 'UPDATE_BOX_WEEKLY_BASKET_RESPONSE';
export const UPDATE_BOX_WEEKLY_BASKET_REQUEST = 'UPDATE_BOX_WEEKLY_BASKET_REQUEST';
export const UPDATE_BOX_WEEKLY_BASKET_ERROR = 'UPDATE_BOX_WEEKLY_BASKET_ERROR';

export const GET_NSO_DELIVERY_DATES = 'GET_NSO_DELIVERY_DATES';
export const GET_NSO_DELIVERY_DATES_REQUEST = 'GET_NSO_DELIVERY_DATES_REQUEST';
export const GET_NSO_DELIVERY_DATES_RESPONSE = 'GET_NSO_DELIVERY_DATES_RESPONSE';
export const GET_NSO_DELIVERY_DATES_ERROR = 'GET_NSO_DELIVERY_DATES_ERROR';

export const createNsoBoxRecipientInfo = params =>
  makeApiRequest({
    requestType: CREATE_NSO_BOX_RECIPIENT_INFO,
    method: RequestService.createNsoRecipientInfo,
    params,
  });

export const updateNsoBoxRecipientInfo = (params, boxId = null) =>
  makeApiRequest({
    requestType: SET_NSO_BOX_RECIPIENT_INFO,
    method: RequestService.updateNsoBoxRecipientInfo,
    params: { boxId, ...params },
  });

export const getNsoBoxRecipientInfo = id =>
  makeApiRequest({
    requestType: GET_NSO_BOX_RECIPIENT_INFO,
    method: RequestService.getNsoBoxRecipientInfo,
    params: { id },
  });

export const createNsoBox = (params = {}) =>
  makeApiRequest({
    requestType: CREATE_NSO_BOX,
    method: RequestService.createNsoBox,
    params,
  });

export const updateNsoBox = (boxId, menuSlug, params) =>
  makeApiRequest({
    requestType: UPDATE_BOX,
    method: RequestService.updateNsoBox,
    params: { boxId, menuSlug, ...params },
  });

export const getNsoBoxes = () =>
  makeApiRequest({
    requestType: GET_NSO_BOXES,
    method: RequestService.getNsoBoxes,
  });

export const getNsoBox = boxId =>
  makeApiRequest({
    requestType: GET_NSO_BOX,
    method: RequestService.getNsoBox,
    params: { boxId },
  });

export const destroyNsoBox = boxId =>
  makeApiRequest({
    requestType: DESTROY_NSO_BOX,
    method: RequestService.destroyNsoBox,
    params: { boxId },
  });

export const submitNsoOrder = params =>
  makeApiRequest({
    requestType: SUBMIT_NSO_ORDER,
    method: RequestService.submitNsoOrder,
    params,
  });

export const confirmNsoOrder = params =>
  makeApiRequest({
    requestType: CONFIRM_NSO_ORDER,
    method: RequestService.setNsoBoxConfirmed,
    params,
  });

export const updateBoxBasket = (boxId, menuSlug, params) =>
  makeApiRequest({
    requestType: UPDATE_BOX_WEEKLY_BASKET,
    method: RequestService.updateBoxWeeklyBasket,
    params: { boxId, menuSlug, ...params },
  });

export const getNsoDeliveryDates = menuSlugs =>
  makeApiRequest({
    requestType: GET_NSO_DELIVERY_DATES,
    method: RequestService.getNsoDeliveryDates,
    params: { menuSlugs },
  });
